<template>
  <div id="toolbar">
    <ion-toolbar mode="ios" >
      <ion-buttons slot="start">
        <slot name="start">
          <ion-button @click="openStart()">
            <ion-icon
              size="large"
              name="md-menu"
            ></ion-icon>
          </ion-button>
        </slot>
      </ion-buttons>
    </ion-toolbar>
  </div>
</template>

<script>
// import MenuLeft from "../components/MenuLeft"
export default {
  computed: {},
  components: {
    // MenuLeft
  },
  methods: {
    routerHome() {
      this.$router.push("/list");
      // this.closeStart();
    },
    openStart() {
      document.querySelector("ion-menu-controller").open("start");
    },
  },
  data() {
    return {
      logo: require("../assets/KiesJijMaarLogo.png"),
    };
  },
};
</script>
<style scoped>
.logo {
  height: 33px;
  padding-top: 8px;
}
/* #toolbar {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
} */
/* .menu-button {
  height: 6vh;
  width: 6vh;
  padding: 5px;
  position: relative;
  margin: 5px;
  float: left;
} */

ion-toolbar {
  --min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
