import firebaseService from "./firebaseService";
import userService from "./userService";
import firebase from "firebase";
import "firebase/firestore";

class ListService {
  constructor() {
    firebaseService.getDb().then((db) => {
      this.db = db;
    });
  }

  async createList(name) {
    const now = firebase.firestore.Timestamp.fromDate(new Date());
    const user = await userService.getUser();
    var listId = "";
    await this.db
      .collection("Lists")
      .add({
        archived: false,
        createdAt: now,
        name: name,
        owner_id: user.uid,
        winner_id: "",
      })
      .then(function (docRef) {
        listId = docRef.id;
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
    return listId;
  }

  async getListById(id) {
    const list = await this.db.collection("Lists").doc(id).get();
    return list.data();
  }

  async createListContributor(listId) {
    const user = await userService.getUserData();
    const authUser = await userService.authUser();

    console.log("USER: ", user);
    console.log("AUTH USER: ", authUser);
    console.log(authUser.email)

    const contributor = await this.db.collection("List_contributors")
      .add({
        user_id: authUser.uid,
        name: user.name,
        list_id: listId,
        is_ready: false,
        email: authUser.email
      });
    localStorage.setItem('contributor_id', contributor.id);
  }



  async createGuestListContributor(listId, guestUser) {

    console.log("USER: ", guestUser);

    const contributor = await this.db.collection("List_contributors")
      .add({
        user_id: guestUser.id,
        name: guestUser.name,
        list_id: listId,
        is_ready: false,
        email: guestUser.email
      });
    localStorage.setItem('contributor_id', contributor.id);
  }
  async setWinner(listId, winnerId) {
    return new Promise((resolve, reject) => {
      this.db.collection("Lists")
        .doc(listId)
        .update({
          winner_id: winnerId
        })
        .then(() => {
          resolve(); // Resolve the promise when the update is successful
        })
        .catch(error => {
          reject(error); // Reject the promise if there's an error
        });
    });
  }
  
}

export default new ListService();