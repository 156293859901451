import { defineStore } from "pinia";
import userService from "../services/userService";
import { io } from "socket.io-client";
import { eventBus } from "../main";
import firebase from 'firebase/app'
import 'firebase/firestore'


export const useSocketStore = defineStore({
    id: 'socket',
    state: () => ({
        socket: null,
        user: {
            id:"",
            name:"",
            email:""
        },
        list: null,
        roomUsers: [],
        roomItems:[],
        createItemsobject:{}
    }),
    actions: {
        async setupSocketConnection(listId, guestUser) {
            try {
                this.socket = io("https://kiesjijmaar.com:3001");
                console.log(guestUser);

                if(firebase.auth().currentUser !== null && guestUser.id == "")
                {
                await this.getFullUser();
                }
                else{
                    console.log("guestUser!!!!!", guestUser);
                    this.user = {
                        id: guestUser.id,
                        name: guestUser.name,
                        email: guestUser.email
                    }
                }
                

                this.socket.emit("join", listId);

                console.log("Joining room");
                console.log("THIS . USER: ", this.user);

                this.socket.emit("register-user", {
                    user: this.user,
                    room: listId,
                });
                console.log("user emitted");

                this.socket.on("register-user-server", this.handleIncomingMessage);
                this.socket.on("user-left", this.handleUserLeaving);
                this.socket.on("list-items", this.getListItemsApp);
                this.socket.on("get-winner", this.getWinner)

            } catch (error) {
                console.error("Error setting up socket connection:", error);
            }
        },
    
        handleIncomingMessage(response) {
            console.log("INCOMING MESSAGE: ", response);
            this.roomUsers = response.allRoomUsers;
            let reponseUser = response.joinedUser.user;
            console.log("RESPONSE USER: ", reponseUser);
            console.log("THIS USER: ", this.user);
            if (reponseUser && reponseUser.id === this.user.id) {
                reponseUser = null;
            }
            eventBus.$emit("new-user-joined", reponseUser);
        },
    
        handleUserLeaving(socket) {
            console.log("USER LEFT: ", socket);
            this.roomUsers = this.roomUsers.filter(
                (u) => u.user.id !== socket.user.id
            );
            eventBus.$emit("user-left", socket.user);
        },
        
        getListItemsApp(response){
            eventBus.$emit("items-grouplist", response);

        },
    
        async getFullUser() {
            console.log("GETTING FULL USER", firebase.auth().currentUser );
            const { uid } = await userService.getUser();
            const user = await userService.getUserData(uid);
            user.id = uid;
    
            this.user = user;
        },

        disconnectSocket() {
            return new Promise((resolve, reject) => {
                try {
                    if (this.socket) {
                        console.log("Attempting to disconnect socket...");
                        // Emit a custom "disconnect" event to the server
                            this.socket.close();
                            console.log("Socket closed.");
                            this.socket = null; // Reset socket to null
                            resolve(); // Resolve the Promise after successful disconnection
                    } else {
                        console.warn("Socket is not initialized.");
                        resolve(); // Resolve the Promise if the socket is not initialized
                    }
                } catch (error) {
                    console.error("Error occurred while disconnecting socket:", error);
                    reject(error); // Reject the Promise if an error occurs
                }
            });
        },
        createItems(items, contributorid, listId){
            this.createItemsobject.items = items;
            this.createItemsobject.contributorid = contributorid;
            this.createItemsobject.listid = listId;
            this.socket.emit("create-items", this.createItemsobject);
        },
        getListItemsSocket(room){
            this.socket.emit("get-items", room);
        },
        
        sendWinner(roomid){
            this.socket.emit("send-winner", roomid);
        },
        getWinner(){
            eventBus.$emit("get-winner");
        }
        
        
        
    }
});