<template>
  <!-- Header -->
  <ion-header no-border animated>
    <!-- Opens menu -->
    <Toolbar>
      <template #start>
        <ion-button v-if="!showDefault" @click="back">
          <ion-icon size="large" name="ios-arrow-round-back" color="primary"></ion-icon>
        </ion-button>
        <ion-button v-if="showDefault" @click="openStart()">
          <ion-icon size="large" name="md-menu"></ion-icon>
        </ion-button>
        <!-- </ion-button> -->
      </template>

      <!-- On-screen profile button -->
      <template v-if="!showDefault" #logo>
        <!-- <img v-if="showDefault" class="logo" :src='require("../assets/KiesJijMaarLogo.png")' /> -->
        <p />
      </template>
    </Toolbar>
    <!-- Opens menu -->


  </ion-header>
  <!-- Header -->
</template>
  
<script>
import Toolbar from "../components/Toolbar";

export default {
  name: "Header",
  components: {
    Toolbar
  },

  data() {
    return {

      list: [],
      showDefault: true,
    };
  },
  methods: {

    routerProfile() {
      this.$router.push("/mykjm");
    },

    back() {
      this.showDefault = !this.showDefault;
      this.stop();
      window.location.reload();
      document.querySelector("ion-menu-controller").enable(true);
    },



    openStart() {
      document.querySelector("ion-menu-controller").open("start");
    },




  }

};
</script>

<style ></style>
  
  